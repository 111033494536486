import styled from 'styled-components';
import MEDIA from '../../../helpers/mediaTemplates';

export const Container = styled.nav`
  ul {
    display: flex;
    flex-direction:row;
    list-style: none;
    padding: 0;
    li {
      font-size: 18px;
      padding-right:20px;
      & + li {
        
      }
    }
  }
  ${MEDIA.PHONE`
  ul {
    display: flex;
    list-style: none;
    padding: 0;
    li {
      font-size: .75rem;
      & + li {
        
      }
    }
  }
  `}
`;