import * as React from "react"
import { Link } from "gatsby"
import { Container,Main, Footer} from './layout.css'
import Header from '../header/header'
import GlobalStyle from '../../global.css';
import NavBar from '../header/navBar/navBar'

const Layout = ({ location, title, children }) => {


  return (
    
      <Container>
        <GlobalStyle />
        <Header/>
        <Main>{children}</Main>
      </Container>
      
    
  )
}

export default Layout
