import React from 'react';
import {Link} from 'gatsby';
import {Container,HomeLink} from './header.css';
import NavBar from './navBar/navBar';

const Header = ({})=>(

    <Container>
        <NavBar/>
    </Container>
)

export default Header;