import styled from 'styled-components';
import MEDIA from '../../helpers/mediaTemplates';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  padding-bottom: 100px;
  min-height:100vh;
  padding-left:20%;
  padding-right:20%;
  padding-top:1%;
  ${MEDIA.TABLET`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 2rem;
  position: relative;
  overflow: hidden;
  padding-bottom: 100px;
  `}
  ${MEDIA.PHONE`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 15px;
  position: relative;
  overflow: hidden;
  padding-bottom: 100px;
  `}
`;

export const Main = styled.div`
display:flex;
flex:5;
flex-direction:column;

h1 {
  margin-top: 0;
  font-size: 3.052rem;
}

h2 {font-size: 2.441rem;}

h3 {font-size: 1.953rem;}

h4 {font-size: 1.563rem;}

h5 {font-size: 1.25rem;}
`