import * as React from "react"
import { Link } from "gatsby"
import {Container} from './navBar.css';


const NavBar = ()=>{
    return(
   <Container>
       <ul>
         <li>Read</li>
         <li>Buy</li>
         <li>Subscribe</li>
         <li>Connect Wallet</li>
       </ul>
   </Container>
      )
}

export default NavBar;